@import '../../variables';

.footer {
  background-color: $card-background;
  color: $text-color;
  padding: $spacing-unit * 7.5 0;
  width: 100%;
  position: relative;
  z-index: 2;
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $spacing-unit * 2.5;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__column {
    flex: 1;
    margin-bottom: $spacing-unit * 3.75;
    min-width: 200px;

    h3 {
      color: $primary-color;
      margin-bottom: $spacing-unit * 2.5;
      font-size: 1.2rem;
      font-weight: 600;
    }

    p, a, ul li {
      margin-bottom: $spacing-unit * 1.25;
      color: $text-color;
      font-size: 0.9rem;
      line-height: 1.6;
    }

    a {
      text-decoration: none;
      transition: color $transition-duration ease;

      &:hover {
        color: $primary-color;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }

    i {
      margin-right: $spacing-unit * 1.25;
      color: $primary-color;
    }
  }

  &__company {
    max-width: 300px;
  }

  &__logo {
    max-width: 200px;
    margin-bottom: $spacing-unit * 2.5;
  }

  &__link {
    display: block;
    margin-bottom: $spacing-unit * 1.25;
  }

  &__services, &__contact {
    ul li {
      margin-bottom: $spacing-unit * 1.875;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .footer {
    &__columns {
      flex-direction: column;
    }

    &__column {
      width: 100%;
      text-align: center;
      margin-bottom: $spacing-unit * 5;
    }

    &__company {
      max-width: 100%;
    }
  }
}

