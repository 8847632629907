@import '../../variables';

.consultation-program {
  &__hero {
    background: linear-gradient(135deg, $background-color, lighten($background-color, 10%));
    color: $text-color;
    padding: $spacing-unit * 12.5 0;
    text-align: center;

    h1 {
      font-size: 3rem;
      margin-bottom: $spacing-unit * 2;
      color: $primary-color;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: $spacing-unit * 3;
    }
  }

  &__main {
    padding: $spacing-unit * 6 0;
  }

  &__info {
    h2 {
      font-size: 2rem;
      margin-bottom: $spacing-unit * 2;
      color: $primary-color;
    }

    p {
      font-size: 1rem;
      margin-bottom: $spacing-unit * 3;
    }
  }
}

