@import '../../../variables';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all $transition-duration ease;
  padding: $spacing-unit * 2.5 0;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#1E1E1E, 0.2);
    backdrop-filter: blur(10px);
    transition: all $transition-duration ease;
    opacity: 1;
  }

  &.scrolled {
    padding: $spacing-unit * 2 0;

    .navbar__background {
      background-color: rgba($primary-color, 0.8);
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    cursor: pointer;
    img {
      height: 40px;
      transition: all $transition-duration ease;

      .scrolled & {
        height: 35px;
      }
    }
  }

  &__toggle {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    span {
      width: 30px;
      height: 3px;
      background: $text-color;
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    color: $text-color;
    text-decoration: none;
    padding: $spacing-unit $spacing-unit;
    transition: color $transition-duration ease;
    font-size: 0.9rem;
    font-weight: $font-weight-medium;
    background: none;
    border: none;
    cursor: pointer;

    &:hover, &.active {
      color: lighten($primary-color, 20%);
    }
  }

  &__cta {
    margin-left: $spacing-unit * 2;
  }
}

@media (max-width: $breakpoint-lg) {
  .navbar {
    &__toggle {
      display: flex;
    }

    &__menu {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      flex-direction: column;
      background-color: rgba($background-color, 0.95);
      padding: $spacing-unit * 2;
      clip-path: circle(0px at top right);
      transition: clip-path 0.5s ease-in-out;
    }

    &.menu-open &__menu {
      clip-path: circle(150% at top right);
    }

    &__links {
      flex-direction: column;
      width: 100%;
    }

    &__link {
      padding: $spacing-unit 0;
      width: 100%;
      text-align: left;
    }

    &__cta {
      margin: $spacing-unit * 2 0 0;
      width: 100%;
      text-align: center;
    }
  }
}

