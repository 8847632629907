// Colors
$primary-color: #3498db;
$primary-color-dark: #2980b9;
$secondary-color: #2ecc71;
$text-color: #e0e0e0;
$background-color: #121212;
$card-background: #1e1e1e;
$card-hover-background: #2a2a2a;

// Typography
$font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
$font-size-base: 16px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Spacing
$spacing-unit: 8px;

// Border Radius
$border-radius: 4px;

// Transitions
$transition-duration: 0.3s;

