@import '../../../../variables';

.design-blocks {
  background-color: $card-background;
  padding: $spacing-unit * 10 0;

  .timeline {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    padding: $spacing-unit * 4 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: $primary-color;
      left: 50%;
      margin-left: -2px;
    }
  }

  .timeline-item {
    padding: $spacing-unit * 4 0;
    position: relative;
    width: 50%;
    
    .timeline-point {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      color: #fff;
    }

    &.left {
      left: 0;
      padding-right: $spacing-unit * 5;

      .timeline-content {
        text-align: right;
      }

      .timeline-point {
        right: -25px;
      }
    }

    &.right {
      left: 50%;
      padding-left: $spacing-unit * 5;

      .timeline-point {
        left: -25px;
      }
    }
  }

  .timeline-content {
    padding: $spacing-unit * 3;
    background-color: lighten($card-background, 5%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    transition: transform $transition-duration ease, background-color $transition-duration ease;

    &:hover {
      transform: translateY(-5px);
      background-color: lighten($card-background, 10%);
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: $spacing-unit * 2;
      color: $primary-color;
    }

    p {
      font-size: 1rem;
      color: $text-color;
      margin-bottom: $spacing-unit * 2;
    }

    ul {
      padding-left: $spacing-unit * 2.5;
      color: $text-color;

      li {
        margin-bottom: $spacing-unit;
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .design-blocks {
    .timeline {
      &::before {
        left: 30px;
      }
    }

    .timeline-item {
      width: 100%;
      padding-left: 70px;
      padding-right: $spacing-unit * 2;

      &.left, &.right {
        left: 0;

        .timeline-point {
          left: 5px;
        }

        .timeline-content {
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .design-blocks {
    .timeline-item {
      padding-left: 50px;

      .timeline-point {
        width: 40px;
        height: 40px;
      }

      .timeline-content {
        padding: $spacing-unit * 2;

        h3 {
          font-size: 1.2rem;
        }

        p, ul li {
          font-size: 0.9rem;
        }
      }
    }
  }
}