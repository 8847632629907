@import '../../../variables';

.presentation {
  .hero {
    background: linear-gradient(135deg, $background-color, lighten($background-color, 10%));
    color: $text-color;
    padding: $spacing-unit * 12.5 0;
    min-height: 100vh;
    display: flex;
    align-items: center;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .hero-content {
      flex: 1;

      h1 {
        font-size: 3rem;
        margin-bottom: $spacing-unit * 2;
        color: $primary-color;
      }

      p {
        font-size: 1.2rem;
        margin-bottom: $spacing-unit * 3;
        color: $text-color;
      }
    }

    .hero-image {
      flex: 1;
      text-align: right;

      img {
        max-width: 100%;
        height: auto;
        filter: drop-shadow(0 0 10px rgba($primary-color, 0.5));
      }
    }
  }

  main {
    padding: $spacing-unit * 6 0;
  }
}

@media (max-width: $breakpoint-md) {
  .presentation {
    .hero {
      padding: $spacing-unit * 10 0;

      .container {
        flex-direction: column;
        text-align: center;
      }

      .hero-content, .hero-image {
        flex: none;
        width: 100%;
      }

      .hero-content {
        margin-bottom: $spacing-unit * 4;

        h1 {
          font-size: 2.5rem;
        }

        p {
          font-size: 1rem;
        }
      }

      .hero-image {
        margin-top: $spacing-unit * 4;
      }
    }
  }
}

