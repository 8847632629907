@import '../../variables';

.consultation-form {
  background-color: $card-background;
  padding: $spacing-unit * 4;
  border-radius: $border-radius;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 2rem;
    margin-bottom: $spacing-unit * 3;
    color: $primary-color;
    text-align: center;
  }

  .MuiTextField-root {
    margin-bottom: $spacing-unit * 2;
  }

  .MuiInputBase-root {
    color: $text-color;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(255, 255, 255, 0.23);
    }

    &:hover fieldset {
      border-color: rgba(255, 255, 255, 0.5);
    }

    &.Mui-focused fieldset {
      border-color: $primary-color;
    }
  }

  .MuiInputLabel-root {
    color: rgba(255, 255, 255, 0.7);

    &.Mui-focused {
      color: $primary-color;
    }
  }

  .MuiInputAdornment-root {
    .MuiSvgIcon-root {
      color: $text-color;
    }
  }

  .submit-button {
    margin-top: $spacing-unit * 3;
    width: 100%;
    padding: $spacing-unit * 1.5;
    font-size: 1rem;
    background-color: $primary-color;
    color: $text-color;
    transition: background-color $transition-duration ease;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}

