@import '../../../../variables';

.information {
    background-color: $background-color;
    padding: $spacing-unit * 10 0;
    width: 100%;
    box-sizing: border-box;
  
    .info-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: $spacing-unit * 4;
    }
  
    .info-card {
      background-color: $card-background;
      border-radius: $border-radius;
      padding: $spacing-unit * 4;
      text-align: center;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform $transition-duration ease, background-color $transition-duration ease;
  
      &:hover {
        transform: translateY(-5px);
        background-color: lighten($card-background, 5%);
      }
  
      .icon {
        font-size: 3rem;
        color: $secondary-color;
        margin-bottom: $spacing-unit * 2;
      }
  
      h3 {
        font-size: 1.5rem;
        margin-bottom: $spacing-unit * 2;
        color: $primary-color;
      }
  
      p {
        font-size: 1rem;
        color: $text-color;
      }
    }
  }
  
  @media (max-width: $breakpoint-md) {
    .information {
      padding: $spacing-unit * 6 0;
  
      .info-grid {
        grid-template-columns: 1fr;
        gap: $spacing-unit * 3;
      }
    }
  }