@import '../../../variables';

.contact-us {
  &__hero {
    background: linear-gradient(135deg, $background-color, lighten($background-color, 10%));
    color: $text-color;
    padding: $spacing-unit * 12.5 0;
    text-align: center;

    h1 {
      font-size: 3rem;
      margin-bottom: $spacing-unit * 2;
      color: $primary-color;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: $spacing-unit * 3;
    }
  }

  &__main {
    padding: $spacing-unit * 6 0;
  }

  &__content {
    display: flex;
    gap: $spacing-unit * 4;

    @media (max-width: $breakpoint-md) {
      flex-direction: column;
    }
  }
}

.contact-info {
  flex: 1;
  background-color: $card-background;
  padding: $spacing-unit * 4;
  border-radius: $border-radius;

  h2 {
    font-size: 2rem;
    margin-bottom: $spacing-unit * 2;
    color: $primary-color;
  }

  p {
    margin-bottom: $spacing-unit * 3;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin-bottom: $spacing-unit * 3;

    li {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-unit * 2;

      svg {
        margin-right: $spacing-unit * 2;
        color: $primary-color;
      }
    }
  }

  &__social {
    display: flex;
    gap: $spacing-unit * 2;

    a {
      color: $text-color;
      transition: color $transition-duration ease;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.contact-form {
  flex: 1;
  background-color: $card-background;
  padding: $spacing-unit * 4;
  border-radius: $border-radius;

  h2 {
    font-size: 2rem;
    margin-bottom: $spacing-unit * 2;
    color: $primary-color;
  }

  p {
    margin-bottom: $spacing-unit * 3;
  }

  .btn {
    width: 100%;
  }
}

