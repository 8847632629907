@import '../../../../variables';

.three-principals {
  background-color: $card-background;
  padding: $spacing-unit * 10 0;

  .principals-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $spacing-unit * 4;
  }

  .principal-card {
    background-color: lighten($card-background, 5%);
    border-radius: $border-radius;
    padding: $spacing-unit * 4;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform $transition-duration ease, background-color $transition-duration ease;

    &:hover {
      transform: translateY(-5px);
      background-color: lighten($card-background, 10%);
    }

    .icon {
      font-size: 3rem;
      color: $primary-color;
      margin-bottom: $spacing-unit * 2;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: $spacing-unit * 2;
      color: $primary-color;
    }

    p {
      font-size: 1rem;
      color: $text-color;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .three-principals {
    padding: $spacing-unit * 6 0;

    .principals-grid {
      grid-template-columns: 1fr;
      gap: $spacing-unit * 3;
    }
  }
}

