@import 'variables';

html, body {
  overflow-x: hidden;
  height: 100%;
}

body {
  font-family: $font-family;
  font-size: $font-size-base;
  line-height: 1.6;
  color: $text-color;
  background-color: $background-color;
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100vh;
}

// Custom scrollbar styles
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: $background-color;
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  border-radius: 6px;
  border: 3px solid $background-color;
}

::-webkit-scrollbar-thumb:hover {
  background-color: darken($primary-color, 10%);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1 0 auto;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
  padding-top: 80px; // Add padding to account for fixed navbar
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 $spacing-unit * 2;
  box-sizing: border-box;
}

.btn {
  display: inline-block;
  padding: $spacing-unit * 1.5 $spacing-unit * 3;
  background-color: $primary-color;
  color: #fff;
  text-decoration: none;
  border-radius: $border-radius;
  transition: background-color $transition-duration ease;
  border: none;
  cursor: pointer;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;

  &:hover {
    background-color: $primary-color-dark;
  }
}

.section-title {
  font-size: 2.5rem;
  font-weight: $font-weight-bold;
  text-align: center;
  margin-bottom: $spacing-unit * 3;
  color: $primary-color;
}

// Add more global styles as needed

