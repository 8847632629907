@import '../../variables';

.careers-page {
  .careers-hero {
    background: linear-gradient(135deg, $background-color, lighten($background-color, 10%));
    color: #000000;
    padding: $spacing-unit * 12.5 0;
    text-align: center;

    h1 {
      font-size: 3rem;
      margin-bottom: $spacing-unit * 2;
      color: $primary-color;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: $spacing-unit * 3;
      color: #fff;
    }
  }

  .careers-main {
    padding: $spacing-unit * 6 0;
  }

  .career-card {
    background-color: $card-background;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: $spacing-unit * 2.5;
    transition: all 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;

    transition: background-color 1.5s ease, color 1.5s ease, transform 1.5s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      background-color: lighten($card-background, 5%);
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: $spacing-unit;
      color: $primary-color;
    }

    .department {
      font-size: 1rem;
      color: $text-color;
      margin-bottom: $spacing-unit;
    }

    .date-posted {
      font-size: 0.9rem;
      color: darken($text-color, 20%);
    }

    .apply-btn {
      background-color: #2596be;
      color: white;
      margin-top: auto;

      &:hover {
        background-color: #6fa7bb;
      }
    }
  }

  .no-vacancies-card {
    background-color: $card-background;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: $spacing-unit * 4;
    text-align: center;

    h2 {
      font-size: 2rem;
      margin-bottom: $spacing-unit * 2;
      color: $primary-color;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: $spacing-unit * 3;
        color: #fff;
    }
  }
}

