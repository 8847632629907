.scroll-animation {
  opacity: 0;
  transition: all 0.8s ease-out;
  will-change: opacity, transform;

  &.is-visible {
    opacity: 1;
  }

  &.fade-in {
    transform: translateY(20px);

    &.is-visible {
      transform: translateY(0);
    }
  }

  &.slide-in-left {
    transform: translateX(-100px);

    &.is-visible {
      transform: translateX(0);
    }
  }

  &.slide-in-right {
    transform: translateX(100px);

    &.is-visible {
      transform: translateX(0);
    }
  }

  &.zoom-in {
    transform: scale(0.5);

    &.is-visible {
      transform: scale(1);
    }
  }

  &.rotate-in {
    transform: rotate(-180deg);

    &.is-visible {
      transform: rotate(0);
    }
  }
}

